import type { GetServerSideProps, NextPage } from 'next';

import { withAuthGuardSSR } from '@/store/auth';

import { AuthLoginForm, AuthPageBottomLinks, AuthPageTopActiveLink, AuthPageTopLinks } from '@/features/auth/components';
import { Link } from '@/components/structural';


const AuthLoginPage: NextPage = () =>
{
    return ( <>
        <AuthPageTopLinks>
            <Link to="/auth/signup">Sign up</Link>
            <AuthPageTopActiveLink>Log in</AuthPageTopActiveLink>
        </AuthPageTopLinks>

        <AuthLoginForm/>

        <AuthPageBottomLinks
              left={
                  <Link to="/auth/signup">Don&apos;t have an account yet?</Link>
              }
              right={
                  <Link to="/auth/forgot-password-request">Forgot your password?</Link>
              }
        />
    </> );
};

export default AuthLoginPage;

export const getServerSideProps: GetServerSideProps = ( context ) =>
      withAuthGuardSSR( context )( async () => ( {
          props: {}
      } ) );